import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "mouldData";

// --------------------- Mould Starts Here ---------------------

//Mould
export const getMouldMetaData = createAsyncThunk(
  `${sliceName}/getMouldMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/mould/getMetadata`)
);

export const getMouldRowData = createAsyncThunk(
  `${sliceName}/getMouldRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/mould/getAll?${params || ""}`)
);

export const getMouldStoneProperties = createAsyncThunk(
  `${sliceName}/getMouldStoneProperties`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/mould/stone-property/list`)
);



export const deleteMould = createAsyncThunk(
  `${sliceName}/deleteMould`,
  async ({ id }) => {
    const res = await fetchWrapper.post(`${baseUrl}/mould/delete/${id}`, { id });
    return res || res === "" ? { id } : null;
  }
);

export const getMouldById = createAsyncThunk(
  `${sliceName}/getMouldById`,
  async (apiParams) => {
    let id = apiParams?.id
    if (!id) {
      id = apiParams
    } 
    return await fetchWrapper.get(`${baseUrl}/mould/get/${id}`)
  }
);

export const updateMouldById = createAsyncThunk(
  `${sliceName}/getMouldById`,
  async (value) => await fetchWrapper.post(`${baseUrl}/mould/update/${value.id}`, value)
);

export const addMould = createAsyncThunk(
  `${sliceName}/addMould`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/mould/add`, values);
    return res || res === "" ? res : null;
  }
);

export const duplicateMould = createAsyncThunk(
  `${sliceName}/duplicateMould`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/mould/duplicate/${id}`)
);

export const getMouldMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getMouldMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/mould/getMetadata?versioning=true&${params || ""}`)
);

export const getMouldRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getMouldRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/mould/version-history/get/${id}?${params || ""}`)
);

// slice
export const mouldSlice = createSlice({
  name: sliceName,
  initialState: {},
});

export default mouldSlice.reducer;
