import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper, generateUpdateApiQueryParams } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "collectionData";

// APIs
/* table Meta Data */
export const getCollectionsTypesMetaData = createAsyncThunk(
  `${sliceName}/getCollectionsTypesMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/collection/collection-type/getMetadata`)
);

export const getCollectionsTypesRowData = createAsyncThunk(
  `${sliceName}/getCollectionsTypesRowData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/collection/collection-type/getAll?${params || ""}`
    )
);

export const getCollectionsTypesList = createAsyncThunk(
  `${sliceName}/getCollectionsTypesList`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/collection/collection-type/dropdown/getAll?${params || ""}`
    )
);

export const getCollectionsTagByCollectionsId = createAsyncThunk(
  `${sliceName}/getCollectionsTagByCollectionsId`,
  async (id) =>
    await fetchWrapper.get(
      `${baseUrl}/collection/collection-type/${id}/all-tags/get`
    )
);
/* table Meta Data */

export const getAllCollectionsTypes = createAsyncThunk(
  `${sliceName}/getAllCollectionsTypes`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/collection/collection-type/get`)
);

export const getCollectionsDropdown = createAsyncThunk(
  `${sliceName}/getCollectionsDropdown`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/collection/dropdown/getAll`)
);

export const getCollectionsTypeById = createAsyncThunk(
  `${sliceName}/getCollectionsTypeByIdWrapper`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(`${baseUrl}/collection/collection-type/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const addCollectionsType = createAsyncThunk(
  `${sliceName}/addCollectionsType`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/collection/collection-type/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const addCollectionsTypeWrapper = createAsyncThunk(
  `${sliceName}/addCollectionsTypeWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/collection/collection-type/wrapper-collection-type/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const deleteCollectionsType = createAsyncThunk(
  `${sliceName}/deleteCollectionsType`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/collection/collection-type/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);
export const editCollectionsType = createAsyncThunk(
  `${sliceName}/editCollectionsType`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/collection/collection-type/update/${values.stateId}`,
      values
    );
    return res || res === "" ? values : null;
  }
);
export const editCollectionsTypeWrapper = createAsyncThunk(
  `${sliceName}/editCollectionsTypeWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/collection/collection-type/wrapper-collection-type/update/${values.stateId}`,
      values.formData
    );
    return res || res === "" ? values : null;
  }
);

// Collectionss
/* table Meta Data */
export const getCollectionssMetaData = createAsyncThunk(
  `${sliceName}/getCollectionssMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/collection/getMetadata`)
);

export const getCollectionssMetaDataByCollectionTypeId = createAsyncThunk(
  `${sliceName}/getCollectionssMetaData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/collection/getMetadata?${params || ""}`)
);

export const getCollectionssRowData = createAsyncThunk(
  `${sliceName}/getCollectionssRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/collection/getAll?${params || ""}`)
);

export const getCollectionsById = createAsyncThunk(
  `${sliceName}/getCollectionsById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/collection/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const addCollections = createAsyncThunk(
  `${sliceName}/addCollections`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/collection/add`, values);
    return res || res === "" ? res : null;
  }
);
export const getCollectionMetal = createAsyncThunk(
  `${sliceName}/getCollectionMetal`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/collection-metal/getLists`, values);
    return res || res === "" ? res : null;
  }
);
export const getCollectionStone = createAsyncThunk(
  `${sliceName}/getCollectionStone`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/collection-stone/getLists`, values);
    return res || res === "" ? res : null;
  }
);
export const addCollectionsWrapper = createAsyncThunk(
  `${sliceName}/addCollectionsWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/collection/wrapper-collection/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const editCollectionWrapper = createAsyncThunk(
  `${sliceName}/editCollectionWrapper`,
  async (values) => {
    let id = values.id;
    let updatedData = values;
    delete updatedData.id;
    const res = await fetchWrapper.post(
      `${baseUrl}/collection/wrapper-collection/update/${id}`,
      updatedData
    );
    return res || res === "" ? values : null;
  }
);

export const deleteCollections = createAsyncThunk(
  `${sliceName}/deleteCollections`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(`${baseUrl}/collection/delete${deletePermanent ? '-permanent' : ''}/${id}`, {
      id,
    });
    return res;
  }
); 

//getCollectionsPropertiesByCollectionsTypeId
export const getCollectionsPropertiesByCollectionsTypeId = createAsyncThunk(
  `${sliceName}/getCollectionsPropertiesByCollectionsTypeId`,
  async (id) =>
    await fetchWrapper.get(`${baseUrl}/collection/collection-type/get/${id}`)
);

// Duplicate Actions
export const duplicateCollection = createAsyncThunk(
  `${sliceName}/duplicateCollection`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/collection/duplicate/${id}`)
);

export const duplicateCollectionUnit = createAsyncThunk(
  `${sliceName}/duplicateCollectionUnit`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/collection/collection-unit/duplicate/${id}`)
);

export const duplicateCollectionType = createAsyncThunk(
  `${sliceName}/duplicateCollectionType`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/collection/collection-type/duplicate/${id}`)
);

export const duplicateCollectionProperty = createAsyncThunk(
  `${sliceName}/duplicateCollectionProperty`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/collection/collection-property/duplicate/${id}`)
);

/* version history*/
// Collections
export const getCollectionsRowDataByCollectionIdForVersion = createAsyncThunk(
  `${sliceName}/getCollectionsRowDataBycollectionIdForVersion`,
  async ({params,id}) => await fetchWrapper.get(`${baseUrl}/collection/version-history/get/${id}?${params || ""}`)
);
export const getCollectionsDataByCollectionIdForVersion = createAsyncThunk(
  `${sliceName}/getCollectionsDataByCollectionIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/collection/getMetadata/?versioning=true&${params || ""}`)
);

// Collections Type
export const getCollectionsTypeRowDataByCollectionIdForVersion = createAsyncThunk(
  `${sliceName}/getCollectionsTypeRowDataByCollectionIdForVersion`,
  async ({params,id}) => await fetchWrapper.get(`${baseUrl}/collection/collection-type/version-history/get/${id}?${params || ""}`)
);
export const getCollectionsTypeDataByCollectionIdForVersion = createAsyncThunk(
  `${sliceName}/getCollectionsTypeDataByCollectionIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/collection/collection-type/getMetadata/?versioning=true&${params || ""}`)
);

//Relation Type
export const getRelationTypeRowData = createAsyncThunk(
  `${sliceName}/getRelationTypeRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/relation-type/getAll`)
);
// slice
export const collectionSlice = createSlice({
  name: sliceName,
  initialState: {},
});

// properites

export const getCollectionsPropertiesMetaData = createAsyncThunk(
  `${sliceName}/getCollectionsPropertiesMetaData`,
  async () =>
    await fetchWrapper.get(
      `${baseUrl}/collection/collection-property/getMetadata`
    )
);
export const getCollectionsPropertiesRowData = createAsyncThunk(
  `${sliceName}/getCollectionsPropertiesRowData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/collection/collection-property/getAll?${params || ""}`
    )
);
export const getAllCollectionsProperties = createAsyncThunk(
  `${sliceName}/getAllCollectionsProperties`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/collection/collection-property/getAll`)
);
export const getCollectionsPropertyById = createAsyncThunk(
  `${sliceName}/getCollectionsPropertyById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/collection/collection-property/get/${id}?filter[include][][relation]=collectionPropertyValues${params ? `&${params || ""}` : ''}`
    )
  }
);

export const getCollectionsPropertiesMetaDataByCollectionsIdForVersion = createAsyncThunk(
  `${sliceName}/getCollectionsPropertiesMetaDataByCollectionsIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/collection/collection-property/getMetadata?versioning=true&${params || ""}`)
);
export const getCollectionsPropertiesRowDataByCollectionsId = createAsyncThunk(
  `${sliceName}/getCollectionsPropertiesRowDataByCollectionsId`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/collection/collection-property/version-history/get/${id}?${params || ""}`)
);

export const addCollectionsPropertyWrapper = createAsyncThunk(
  `${sliceName}/addCollectionsPropertyWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/collection/collection-property/wrapper-collection-properties/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const duplicateCollectionsProperty = createAsyncThunk(
  `${sliceName}/duplicateCollectionsProperty`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/collection/collection-property/duplicate/${id}`)
);

export const deleteCollectionsProperty = createAsyncThunk(
  `${sliceName}/deleteCollectionsProperty`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/collection/collection-property/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);


export const editCollectionsWrapperProperty = createAsyncThunk(
  `${sliceName}/editCollectionsProperty`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/collection/collection-property/wrapper-collection-properties/update/${values.id}`,
      values.formData
    );
    return res || res === "" ? values : null;
  }
);

// units 
export const getCollectionsUnitsMetaData = createAsyncThunk(
  `${sliceName}/getCollectionsUnitsMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/collection/collection-unit/getMetadata`)
);

export const getCollectionsUnitsRowData = createAsyncThunk(
  `${sliceName}/getCollectionsUnitsRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/collection/collection-unit/getAll?${params || ""}`)
);

export const deleteCollectionsUnit = createAsyncThunk(
  `${sliceName}/deleteCollectionsUnit`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/collection/collection-unit/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const addCollectionsUnit = createAsyncThunk(
  `${sliceName}/addCollectionsUnit`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/collection/collection-unit/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const editCollectionsUnit = createAsyncThunk(
  `${sliceName}/editCollectionsUnit`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/collection/collection-unit/update/${values.id}`,
      values.formData
    );
    return res || res === "" ? values : null;
  }
);

export const getCollectionsUnitById = createAsyncThunk(
  `${sliceName}/getCollectionsUnitById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/collection/collection-unit/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const duplicateCollectionsUnit = createAsyncThunk(
  `${sliceName}/duplicateCollectionsUnit`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/collection/collection-unit/duplicate/${id}`)
);

export const getCollectionsUnitsMetaDataByCollectionsIdForVersion = createAsyncThunk(
  `${sliceName}/getCollectionsUnitsMetaDataByCollectionsIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/collection/collection-unit/getMetadata?versioning=true&${params || ""}`)
);

export const getCollectionsUnitsRowDataByCollectionsId = createAsyncThunk(
  `${sliceName}/getCollectionsUnitsRowDataByCollectionsId`,
  async ({params,id}) => await fetchWrapper.get(`${baseUrl}/collection/collection-unit/version-history/get/${id}?${params || ""}`)
);

export const getAllCollectionsUnits = createAsyncThunk(
  `${sliceName}/getAllCollectionsUnits`,
  async () => await fetchWrapper.get(`${baseUrl}/collection/collection-unit/getAll`)
);


export const getCollectionsPropertiesRowDataByCollectionsIdForVersion = createAsyncThunk(
  `${sliceName}/getCollectionsPropertiesRowDataByCollectionsIdForVersion`,
  async ({params,id}) => await fetchWrapper.get(`${baseUrl}/collection/collection-property/version-history/get/${id}?${params || ""}`)
);

export const getVendorRowDatas = createAsyncThunk(
  `${sliceName}/getVendorRowDatas`,
  async (params = "") => await fetchWrapper.get(`${baseUrl}/vendor/getAll?${params}`,)
);

export const getVendorByLocation = createAsyncThunk(
  `${sliceName}/getVendorByLocation`,
  async (locationId = "") => await fetchWrapper.get(`${baseUrl}/getVendorByLocation/${locationId}`,)
);

export const getStoneMarkupTypes = createAsyncThunk(
  `${sliceName}/getStoneMarkupTypes`,
  async () =>
    await fetchWrapper.get(
      `${baseUrl}/stone/stone-markup/markup-types`
    )
);

export const getAllCollectionsPropertiesDropdowns = createAsyncThunk(
  `${sliceName}/getAllCollectionsPropertiesDropdowns`,
  async () => await fetchWrapper.get(`${baseUrl}/collection/collection-property/values/dropdown/getAll`)
);



export const getCollectionPropertyList = createAsyncThunk(
  `${sliceName}/getCollectionPropertyList`,
  async () =>
    await fetchWrapper.get(
      `${baseUrl}/collection/collection-property/dropdown/getAll`
    )
);

export const getCollectionPropertyValuesList = createAsyncThunk(
  `${sliceName}/getCollectionPropertyValuesList`,
  async () =>
    await fetchWrapper.get(
      `${baseUrl}/collection/collection-property/values/dropdown/getAll`
    )
);



export default collectionSlice.reducer;
