import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "variantsData";

// --------------------- Variants Starts Here ---------------------

//Variants
export const getVariantOptionMetaData = createAsyncThunk(
  `${sliceName}/getVariantOptionMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/variant-option/getMetadata`)
);

export const getVariantOptionRowData = createAsyncThunk(
  `${sliceName}/getVariantsRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/variant-option/getAll?${params || ""}`)
);


//Variants
export const getVariantSetMetaData = createAsyncThunk(
  `${sliceName}/getVariantSetMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/variant-set/getMetadata`)
);

export const getVariantSetRowData = createAsyncThunk(
  `${sliceName}/getVariantsRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/variant-set/getAll?${params || ""}`)
);

// slice
export const variantsSlice = createSlice({
  name: sliceName,
  initialState: {},
});

export default variantsSlice.reducer;
