import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TreeViewComponent from "_components/TreeView/index";
import InnerLoader from "./InnerLoader";
import ShowLinkedPages from "./TreeView/showLinkedPages";
import CollectionFeatureLink from "./SelectList/collectionFeatureLink";
import FeaturesGroup from "./merchandising/navigationHierarchyForm/FeaturesGroup";

const SelectModal = ({
  onSelection,
  optionsData,
  selection,
  secondaryOption,
  firstLabel,
  secondLabel,
  secondarySelection,
  selected,
  setSelected,
  dataLoading,
  onSecondarySelection,
  secondaryTreeViewOptions,
  isSelectReq = true,
  isRootSelect,
  isDropdown = true,
  viewLinkedPages = false,
  viewLinkedOptions,
  page,
  showFeatureCollectionLink,
  handleBlur,
  handleChange,
  disabled,
  values,
  setFieldValue,
  translations,
  featureGroupdata,
  collectionList,
  action,
  showNhFeatureCollectionLink,
  mediaData
}) => {
  const [expanded, setExpanded] = useState([]);

  return (
    <>
      {
        isDropdown &&
        <div className="pt-2">
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="type-select-label">
              {firstLabel ? firstLabel : "Type"}
            </InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              value={selection}
              onChange={(event) => onSelection(event.target.value)}
              label={firstLabel ? firstLabel : "Type"}
            >
              {Array.isArray(optionsData) &&
                optionsData?.map(({ id, label }) => (
                  <MenuItem key={id} value={id}>
                    {label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      }

      {dataLoading ? (
        <InnerLoader />
      ) : (
        <>
          {secondaryOption?.length > 0 && (
            <div className="pt-4">
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="type-select-label">
                  {secondLabel ? secondLabel : "Type"}
                </InputLabel>
                <Select
                  labelId="type-select-label"
                  id="type-select"
                  value={secondarySelection}
                  onChange={(event) => onSecondarySelection(event.target.value)}
                  // label="Select Navigation"
                  label={secondLabel ? secondLabel : "Type"}
                >
                  {isSelectReq && (
                    <MenuItem key={1} value={0}>
                      Select
                    </MenuItem>
                  )}

                  {Array.isArray(secondaryOption) &&
                    secondaryOption?.map(({ id, label }) => (
                      <MenuItem key={id} value={id}>
                        {label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          )}
          {secondaryTreeViewOptions && (
            <TreeViewComponent
              heading={secondLabel ? secondLabel : "Select link"}
              optionsData={secondaryTreeViewOptions ?? []}
              expanded={expanded}
              selected={selected}
              dataLoading={dataLoading}
              setExpanded={setExpanded}
              isRootSelect={isRootSelect}
              setSelected={setSelected}
            />
          )}
          {viewLinkedPages && (
            <ShowLinkedPages
              optionsData={viewLinkedOptions ?? []}
              expanded={expanded}
              selected={selected}
              dataLoading={dataLoading}
              setExpanded={setExpanded}
              setSelected={setSelected}
              page={page}
            />
          )}
          {
            showFeatureCollectionLink && (
              <CollectionFeatureLink
                handleBlur={handleBlur}
                handleChange={handleChange}
                disabled={false}
                values={values}
                loading={dataLoading}
                setFieldValue={setFieldValue}
                translations={translations}
                featureGroupdata={featureGroupdata}
                collectionList={collectionList}
                action={action}
              />
            )
          }
          {showNhFeatureCollectionLink &&
            <FeaturesGroup
              handleBlur={handleBlur}
              handleChange={handleChange}
              featuresGroupData={featureGroupdata}
              value={values}
              mediaData={mediaData}
              setFieldValue={setFieldValue}
              disabled={disabled}             
              collectionListData={collectionList}
              action={action}
            />
          }
        </>
      )}
    </>
  );
};

export default SelectModal;
