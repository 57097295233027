const customMetadatas = {
  promationIdMapping: [
    {
      keyName: "promotionId",
      headerLabel: "promotion Id",
      filterable: {},
      keymapping: {},
      datakey: ["promotionId"],
      id: "promotionId",
      sortable: false,
      visible: false,
      translatable: false,
      static: true,
      columnMapping: "",
      nestedLevel: "",
      linkable: false,
      linkType: "",
      statusCode: "rowStatusCode",
      hasNestedColumns: false,
      sortableKey: {},
    }
  ],
  stockTagOrder: [
    {
      keyName: "LineItemId",
      headerLabel: "OrderLineItem",
      filterable: {},
      keymapping: {
        orderLineItem: {
          type: "object",
          heirarchy: 1,
        },
      },
      datakey: ["orderLineItemId"],
      id: "LineItemId",
      sortable: true,
      visible: false,
      translatable: false,
      static: true,
      columnMapping: "",
      nestedLevel: "",
      linkable: false,
      linkType: "",
      statusCode: "rowStatusCode",
      hasNestedColumns: true,
      sortableKey: {},
    },
  ],
  customer: [
    {
      keyName: "customerName",
      headerLabel: "customerName",
      sortable: false,
      visible: false,
      translatable: false,
      filterable: {
        type: "text",
      },
      keymapping: {},
      datakey: ["firstname"],
      static: true,
      id: "customerName",
      columnMapping: "customer",
      nestedLevel: "1",
      linkable: false,
      linkType: "",
      statusCode: "rowStatusCode",
      hasNestedColumns: false,
      isNestedCol: true,
    },
    {
      keyName: "customerId",
      headerLabel: "customerId",
      sortable: false,
      visible: false,
      translatable: false,
      filterable: {
        type: "text",
      },
      keymapping: {},
      datakey: ["customerId"],
      static: true,
      id: "customerId",
      columnMapping: "",
      nestedLevel: "",
      linkable: false,
      linkType: "",
      statusCode: "rowStatusCode",
      hasNestedColumns: false,
      isNestedCol: false,
    },
    {
      keyName: "customerReferenceId",
      headerLabel: "customerReferenceId",
      sortable: false,
      visible: false,
      translatable: false,
      filterable: {
        type: "text",
      },
      keymapping: {},
      datakey: ["referenceId"],
      static: true,
      id: "customerReferenceId",
      columnMapping: "customer",
      nestedLevel: "1",
      linkable: false,
      linkType: "",
      statusCode: "rowStatusCode",
      hasNestedColumns: false,
      isNestedCol: true,
    },
  ],
  fullProduct: [
    {
      keyName: "stockTagId",
      headerLabel: "stockTagId",
      filterable: {},
      sortable: true,
      visible: true,
      keymapping: {},
      datakey: ["stockTagId"],
      translatable: false,
      static: true,
      id: "stockTagId",
      columnMapping: "",
      nestedLevel: "",
      linkable: false,
      linkType: "",
      statusCode: "rowStatusCode",
      hasNestedColumns: false,
      sortableKey: {},
    },
  ],
};

const getCustomTableMetaDataById = (id) => customMetadatas[id] ?? [];

export default getCustomTableMetaDataById;
