import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper, generateUpdateApiQueryParams } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "findingData";

// APIs
/*Finding Units */
/* table Meta Data */
export const getFindingTypesMetaData = createAsyncThunk(
  `${sliceName}/getFindingTypesMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/finding/finding-type/getMetadata`)
);

export const getFindingTypesRowData = createAsyncThunk(
  `${sliceName}/getFindingTypesRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/finding/finding-type/getAll?${params || ""}`)
);

export const getFindingTypesList = createAsyncThunk(
  `${sliceName}/getFindingTypesList`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/finding/finding-type/dropdown/getAll?${params || ""}`)
);
/* table Meta Data */

export const getAllFindingUnits = createAsyncThunk(
  `${sliceName}/getAllFindingUnits`,
  async () => await fetchWrapper.get(`${baseUrl}/finding/finding-unit/getAll`)
);

export const getFindingUnitById = createAsyncThunk(
  `${sliceName}/getFindingUnitById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/finding/finding-unit/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const addFindingUnit = createAsyncThunk(
  `${sliceName}/addFindingUnit`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/finding/finding-unit/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const deleteFindingUnit = createAsyncThunk(
  `${sliceName}/deleteFindingUnit`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/finding/finding-unit/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

//FindingType
/* table Meta Data */
export const getFindingUnitsMetaData = createAsyncThunk(
  `${sliceName}/getFindingUnitsMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/finding/finding-unit/getMetadata`)
);

export const getFindingUnitsRowData = createAsyncThunk(
  `${sliceName}/getFindingUnitsRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/finding/finding-unit/getAll?${params || ""}`)
);
/* table Meta Data */

export const getFindingTypeById = createAsyncThunk(
  `${sliceName}/getFindingTypeById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/finding/finding-type/get/${id}`)
);

export const getFindingTypeByIdWrapper = createAsyncThunk(
  `${sliceName}/getFindingTypeByIdWrapper`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/finding/finding-type/get/${id}?filter[include][][relation]=allowedPropertiesValueMap${params ? `&${params || ""}` : ''}`
    )
  }
);

export const addFindingType = createAsyncThunk(
  `${sliceName}/addFindingType`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/finding/finding-type/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const addFindingTypeWrapper = createAsyncThunk(
  `${sliceName}/addFindingTypeWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/finding/finding-type/wrapper-finding-type/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const deleteFindingType = createAsyncThunk(
  `${sliceName}/deleteFindingType`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/finding/finding-type/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);


//Finding Property
/* table Meta Data */
export const getFindingPropertiesMetaData = createAsyncThunk(
  `${sliceName}/getFindingPropertiesMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/finding/finding-property/getMetadata`)
);

export const getFindingPropertiesRowData = createAsyncThunk(
  `${sliceName}/getFindingPropertiesRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/finding/finding-property/getAll?${params || ""}`)
);
/* table Meta Data */

export const getAllFindingProperties = createAsyncThunk(
  `${sliceName}/getAllFindingProperties`,
  async () => await fetchWrapper.get(`${baseUrl}/finding/finding-property/getAll`)
);


export const getFindingPropertyById = createAsyncThunk(
  `${sliceName}/getFindingPropertyById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/finding/finding-property/get/${id}?filter[include][][relation]=findingPropertyValues${params ? `&${params || ""}` : ''}`
    )
  }
);

export const addFindingPropertyWrapper = createAsyncThunk(
  `${sliceName}/addFindingPropertyWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/finding/finding-property/wrapper-finding-properties/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);


export const deleteFindingProperty = createAsyncThunk(
  `${sliceName}/deleteFindingProperty`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/finding/finding-property/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

// Findings
/* table Meta Data */
export const getFindingsMetaData = createAsyncThunk(
  `${sliceName}/getFindingsMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/finding/getMetadata`)
);

export const getFindingsMetaDataByFindingTypeId = createAsyncThunk(
  `${sliceName}/getFindingsMetaData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/finding/getMetadata?${params || ""}`)
);

export const getFindingsTagByFindingId = createAsyncThunk(
  `${sliceName}/getFindingsTagByFindingId`,
  async (id) =>
    await fetchWrapper.get(`${baseUrl}/finding/finding-type/${id}/all-tags/get`)
);

export const getFindingsRowData = createAsyncThunk(
  `${sliceName}/getFindingsRowData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/finding/getAll?${params || ""}`)
);

/* version history*/
// Findings
export const getFindingsRowDataByFindingIdForVersion = createAsyncThunk(
  `${sliceName}/getFindingsRowDataByFindingIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/finding/version-history/get/${id}?${params || ""}`)
);
export const getFindingsDataByFindingIdForVersion = createAsyncThunk(
  `${sliceName}/getFindingsDataByFindingIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/finding/getMetadata/?versioning=true&${params || ""}`)
);

// Finding Properties
export const getFindingPropertyRowDataByFindingIdForVersion = createAsyncThunk(
  `${sliceName}/getFindingPropertyRowDataByFindingIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/finding/finding-property/version-history/get/${id}?${params || ""}`)
);
export const getFindingPropertiesDataByFindingIdForVersion = createAsyncThunk(
  `${sliceName}/getFindingPropertiesDataByFindingIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/finding/finding-property/getMetadata/?versioning=true&${params || ""}`)
);

// Finding Types
export const getFindingTypesRowDataByFindingIdForVersion = createAsyncThunk(
  `${sliceName}/getFindingTypesRowDataByFindingId`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/finding/finding-type/version-history/get/${id}?${params || ""}`)
);
export const getFindingTypesDataByFindingIdForVersion = createAsyncThunk(
  `${sliceName}/getFindingTypesDataByFindingIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/finding/finding-type/getMetadata/?versioning=true&${params || ""}`)
);

// Finding Types
export const getFindingUnitsRowDataByFindingIdForVersion = createAsyncThunk(
  `${sliceName}/getFindingUnitsRowDataByFindingIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/finding/finding-unit/version-history/get/${id}?${params || ""}`)
);
export const getFindingUnitsDataByFindingIdForVersion = createAsyncThunk(
  `${sliceName}/getFindingUnitsDataByFindingIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/finding/finding-unit/getMetadata/?versioning=true&${params || ""}`)
);

/* table Meta Data */
export const getAllFindings = createAsyncThunk(
  `${sliceName}/getAllFindings`,
  async () => await fetchWrapper.get(`${baseUrl}/finding/dropdown/getAll`)
);

export const getAllFindingsList = createAsyncThunk(
  `${sliceName}/getAllFindingsList`,
  async () => await fetchWrapper.get(`${baseUrl}/finding/dropdown/getAll`)
);


export const getFindingById = createAsyncThunk(
  `${sliceName}/getFindingById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/finding/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const addFinding = createAsyncThunk(
  `${sliceName}/addFinding`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/finding/add`, values);
    return res || res === "" ? res : null;
  }
);
export const addFindingWrapper = createAsyncThunk(
  `${sliceName}/addFindingWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/finding/wrapper-finding/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);


export const deleteFinding = createAsyncThunk(
  `${sliceName}/deleteFinding`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(`${baseUrl}/finding/delete${deletePermanent ? '-permanent' : ''}/${id}`, {
      id,
    });
    return res;
  }
);

//getFindingPropertiesByFindingTypeId
export const getFindingPropertiesByFindingTypeId = createAsyncThunk(
  `${sliceName}/getFindingPropertiesByFindingTypeId`,
  async (id) => await fetchWrapper.get(`${baseUrl}/finding/finding-type/get/${id}`)
);

// Duplicate Actions
export const duplicateFinding = createAsyncThunk(
  `${sliceName}/duplicateFinding`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/finding/duplicate/${id}`)
);

export const duplicateFindingUnit = createAsyncThunk(
  `${sliceName}/duplicateFindingUnit`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/finding/finding-unit/duplicate/${id}`)
);

export const duplicateFindingType = createAsyncThunk(
  `${sliceName}/duplicateFindingType`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/finding/finding-type/duplicate/${id}`)
);

export const duplicateFindingProperty = createAsyncThunk(
  `${sliceName}/duplicateFindingProperty`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/finding/finding-property/duplicate/${id}`)
);

export const addFindingBulkUpdates = createAsyncThunk(
  `${sliceName}/addFindingBulkUpdates`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/finding/bulk-update`,
      values
    );
    return res || res === "" ? res : null;
  }
);


// slice
export const findingSlice = createSlice({
  name: sliceName,
  initialState: {},
});

export default findingSlice.reducer;
