import { createAsyncThunk } from "@reduxjs/toolkit";

import { fetchWrapper, generateUpdateApiQueryParams } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "stockproduct";

// APIs
// Coupons
export const getCouponsMetaData = createAsyncThunk(
  `${sliceName}/getCouponsMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/coupons/getMetadata`)
);

export const getCouponsRowData = createAsyncThunk(
  `${sliceName}/getCouponsRowData`,
  async (params) =>{
    const queryParams = new URLSearchParams(params);
    const type = queryParams.get('type');
    queryParams.delete('type');
    queryParams.append('searchKey[relations][][type]', type); 
    return await fetchWrapper.get(`${baseUrl}/coupons/getAll?${queryParams || ""}`)
  }
);

export const getCouponTypeList = createAsyncThunk(
  `${sliceName}/getCouponTypeList`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/coupon-types/dropdown/getAll?${params || ""}`)
);

export const deleteCoupons = createAsyncThunk(
  `${sliceName}/deleteCoupons`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/coupons/delete${deletePermanent ? "-permanent" : ""}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const addCoupons = createAsyncThunk(
  `${sliceName}/addCoupons`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/coupons/addCoupon`, values);
    return res || res === "" ? res : null;
  }
);

export const editCoupons = createAsyncThunk(
  `${sliceName}/editCoupons`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/coupons/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getCouponsById = createAsyncThunk(
  `${sliceName}/getCouponsById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/coupons/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const getCouponsMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getCouponsMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/coupons/getMetadata?versioning=true&${params || ""}`)
);

export const getCouponsRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getCouponsRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/coupons/version-history/get/${id}?${params || ""}`)
);


// CouponsType
export const getCouponsTypeMetaData = createAsyncThunk(
  `${sliceName}/getCouponsTypeMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/coupon-types/getMetadata`)
);

export const getCouponsTypeRowData = createAsyncThunk(
  `${sliceName}/getCouponsTypeRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/coupon-types/getAll?${params || ""}`)
);

export const getCouponsTypeList = createAsyncThunk(
  `${sliceName}/getCouponsTypeList`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/coupon-type/dropdown/getAll?${params || ""}`)
);

export const deleteCouponsType = createAsyncThunk(
  `${sliceName}/deleteCouponsType`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/coupon-types/delete${deletePermanent ? "-permanent" : ""}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const getCouponsTypeMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getCouponsTypeMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/coupon-types/getMetadata?versioning=true&${params || ""}`)
);

export const getCouponsTypeRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getCouponsTypeRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/coupon-types/version-history/get/${id}?${params || ""}`)
);

export const addCouponsType = createAsyncThunk(
  `${sliceName}/addCouponsType`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/coupon-types/add`, values);
    return res || res === "" ? res : null;
  }
);

export const duplicateCouponsType = createAsyncThunk(
  `${sliceName}/duplicateCouponsType`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/coupon-types/duplicate/${id}`)
);

export const editCouponsType = createAsyncThunk(
  `${sliceName}/editCouponsType`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/coupon-types/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getCouponsTypeById = createAsyncThunk(
  `${sliceName}/getCouponsTypeById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/coupon-types/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

// DiscountType
export const getDiscountTypeMetaData = createAsyncThunk(
  `${sliceName}/getDiscountTypeMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/discount-types/getMetadata`)
);

export const getDiscountTypeRowData = createAsyncThunk(
  `${sliceName}/getDiscountTypeRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/discount-types/getAll?${params || ""}`)
);

export const deleteDiscountType = createAsyncThunk(
  `${sliceName}/deleteDiscountType`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/discount-types/delete${deletePermanent ? "-permanent" : ""}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const addDiscountType = createAsyncThunk(
  `${sliceName}/addDiscountType`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/discount-types/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editDiscountType = createAsyncThunk(
  `${sliceName}/editDiscountType`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/discount-types/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getDiscountTypeMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getDiscountTypeMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/discount-types/getMetadata?versioning=true&${params || ""}`)
);

export const getDiscountTypeRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getDiscountTypeRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/discount-types/version-history/get/${id}?${params || ""}`)
);

export const duplicateDiscountType = createAsyncThunk(
  `${sliceName}/duplicateDiscountType`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/discount-types/duplicate/${id}`)
);

export const getDiscountTypeById = createAsyncThunk(
  `${sliceName}/getDiscountTypeById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/discount-types/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const getCouponMetaDataByCouponTypeId = createAsyncThunk(
  `${sliceName}/getCouponMetaDataByCouponTypeId`,
  async (params) => await fetchWrapper.get(`${baseUrl}/coupons/getMetadata?${params || ""}`)
);