import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper, generateUpdateApiQueryParams } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "componentsData";

// --------------------- Components Starts Here ---------------------
export const getComponentsTypesMetaData = createAsyncThunk(
  `${sliceName}/getComponentsTypesMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/component/component-type/getMetadata`)
);
export const getAllComponentsUnits = createAsyncThunk(
  `${sliceName}/getAllComponentsUnits`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/component/component-unit/getAll`)
);
export const getComponentsPropertiesMetaData = createAsyncThunk(
  `${sliceName}/getComponentsPropertiesMetaData`,
  async () =>
    await fetchWrapper.get(
      `${baseUrl}/component/component-property/getMetadata`
    )
);
export const getComponentsPropertiesRowData = createAsyncThunk(
  `${sliceName}/getComponentsPropertiesRowData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/component/component-property/getAll?${params || ""}`
    )
);
export const getComponentsPropertyById = createAsyncThunk(
  `${sliceName}/getComponentsPropertyById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/component/component-property/get/${id}?filter[include][][relation]=componentPropertyValues${params ? `&${params || ""}` : ''}`
    )
  }
);

export const getComponentsPropertiesMetaDataByComponentsIdForVersion = createAsyncThunk(
  `${sliceName}/getComponentsPropertiesMetaDataByComponentsIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/component/component-property/getMetadata?versioning=true&${params || ""}`)
);
export const getComponentsPropertiesRowDataByComponentsId = createAsyncThunk(
  `${sliceName}/getComponentsPropertiesRowDataByComponentsId`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/component/component-property/version-history/get/${id}?${params || ""}`)
);

export const addComponentsPropertyWrapper = createAsyncThunk(
  `${sliceName}/addComponentsPropertyWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/component/component-property/wrapper-component-properties/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);
export const deleteComponentsProperty = createAsyncThunk(
  `${sliceName}/deleteComponentsProperty`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/component/component-property/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);
export const duplicateComponentsProperty = createAsyncThunk(
  `${sliceName}/duplicateComponentsProperty`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/component/component-property/duplicate/${id}`)
);
export const getComponentsTypeById = createAsyncThunk(
  `${sliceName}/getComponentsTypeById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(`${baseUrl}/component/component-type/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const getComponentsTypeByIdWrapper = createAsyncThunk(
  `${sliceName}/getComponentsTypeById`,
  async (id) =>
    await fetchWrapper.get(
      `${baseUrl}/component/component-type/get/${id}?filter[include][][relation]=allowedPropertiesValueMap`
    )
);
export const addComponentsTypeWrapper = createAsyncThunk(
  `${sliceName}/addComponentsTypeWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/component/component-type/wrapper-component-type/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const getComponentsTypesMetaDataByComponentsIdForVersion = createAsyncThunk(
  `${sliceName}/getComponentsTypesMetaDataByComponentsIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/component/component-type/getMetadata?versioning=true&${params || ""}`)
);
export const getComponentsTypesRowDataByComponentId = createAsyncThunk(
  `${sliceName}/getComponentsTypesRowDataByComponentId`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/component/component-type/version-history/get/${id}?${params || ""}`)
);
export const getAllComponentsProperties = createAsyncThunk(
  `${sliceName}/getAllComponentsProperties`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/component/component-property/getAll`)
);
export const getComponentsTypesRowData = createAsyncThunk(
  `${sliceName}/getComponentsTypesRowData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/component/component-type/getAll?${params || ""}`
    )
);
export const getComponentsTypesList = createAsyncThunk(
  `${sliceName}/getComponentsTypesList`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/component/component-type/dropdown/getAll?${params || ""}`
    )
);
export const getComponentsMetaDataByComponenetTypeId = createAsyncThunk(
  `${sliceName}/getComponentsMetaDataByComponenetTypeId`,
  async (params) => await fetchWrapper.get(`${baseUrl}/component/getMetadata?${params || ""}`)
);

export const getComponentsPropertiesByComponentsTypeId = createAsyncThunk(
  `${sliceName}/getComponentsPropertiesByComponentsTypeId`,
  async (id) =>
    await fetchWrapper.get(`${baseUrl}/component/component-type/get/${id}`)
);
export const getAllComponentsAllProperties = createAsyncThunk(
  `${sliceName}/getAllComponentsAllProperties`,
  async (componentTypeId) =>
    await fetchWrapper.get(`${baseUrl}/component/component-type/${componentTypeId}/all-properties`)
);
export const addComponentsWrapper = createAsyncThunk(
  `${sliceName}/addComponentsWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/component/wrapper-component/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);
export const getComponentsRowDataByComponentsId = createAsyncThunk(
  `${sliceName}/getComponentsRowDataByComponentsId`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/component/version-history/get/${id}?${params || ""}`)
);
export const getComponentsMetaDataByComponentsIdForVersion = createAsyncThunk(
  `${sliceName}/getComponentsMetaDataByComponentsIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/component/getMetadata?versioning=true&${params || ""}`)
);
export const getComponentsById = createAsyncThunk(
  `${sliceName}/getComponentsById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/component/get/${id}?filter[include][][relation]=componentPropertyValues${params ? `&${params || ""}` : ''}`
    )
  }
);

export const getComponentsTagByComponentsId = createAsyncThunk(
  `${sliceName}/getComponentsTagByComponentsId`,
  async (id) =>
    await fetchWrapper.get(`${baseUrl}/component/component-type/${id}/all-tags/get`)
);
export const duplicateComponentsType = createAsyncThunk(
  `${sliceName}/duplicateComponentsType`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/component/component-type/duplicate/${id}`)
);
export const deleteComponentsType = createAsyncThunk(
  `${sliceName}/deleteComponentsType`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/component/component-type/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);
//Components
export const getComponentsMetaData = createAsyncThunk(
  `${sliceName}/getComponentsMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/component/getMetadata`)
);

export const getComponentsRowData = createAsyncThunk(
  `${sliceName}/getComponentsRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/component/getAll?${params || ""}`)
);
export const getComponentsUnitsMetaData = createAsyncThunk(
  `${sliceName}/getComponentUnitsMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/component/component-unit/getMetadata`)
);
export const deleteComponents = createAsyncThunk(
  `${sliceName}/deleteComponents`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(`${baseUrl}/component/delete${deletePermanent ? '-permanent' : ''}/${id}`, {
      id,
    });
    return res;
  }
);
export const getComponentsUnitsRowData = createAsyncThunk(
  `${sliceName}/getComponentsUnitsRowData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/component/component-unit/getAll?${params || ""}`
    )
);
export const duplicateComponents = createAsyncThunk(
  `${sliceName}/duplicateComponents`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/component/duplicate/${id}`)
);
export const duplicateComponentsUnit = createAsyncThunk(
  `${sliceName}/duplicateComponentsUnit`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/component/component-unit/duplicate/${id}`)
);

export const addComponentsUnit = createAsyncThunk(
  `${sliceName}/addComponentsUnit`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/component/component-unit/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const addComponentBulkUpdates = createAsyncThunk(
  `${sliceName}/addComponentBulkUpdates`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/component/bulk-update`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const deleteComponentsUnit = createAsyncThunk(
  `${sliceName}/deleteComponentsUnit`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/component/component-unit/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);
export const getComponentsUnitById = createAsyncThunk(
  `${sliceName}/getComponentsUnitById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/component/component-unit/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const getComponentsUnitsMetaDataByComponentsIdForVersion = createAsyncThunk(
  `${sliceName}/getComponentsUnitsMetaDataByComponentsIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/component/component-unit/getMetadata?versioning=true&${params || ""}`)
);

export const getComponentsUnitsRowDataByComponentsId = createAsyncThunk(
  `${sliceName}/getComponentsUnitsRowDataByComponentsId`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/component/component-unit/version-history/get/${id}?${params || ""}`)
);

// slice
export const componentsSlice = createSlice({
  name: sliceName,
  initialState: {},
});

export default componentsSlice.reducer;
